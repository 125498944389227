import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import IndexPage from "../components/index"
import {graphql} from "gatsby";

const Index = () => (
  <Layout>
    <Seo title="Anasayfa" />
    <IndexPage/>
  </Layout>
)

export default Index
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
