import React, { useState, useEffect } from "react"
import "./index.scss"
import "../typohraphy.scss"
import CtaType1 from "../cta/cta-type-1/cta-type-1";
import CtaType2 from "../cta/cta-type-2/cta-type-2";
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import axios from "axios";
import { useI18next } from 'gatsby-plugin-react-i18next';

const IndexPage = () => {
    const { language, languages, changeLanguage } = useI18next();
    const [homePageTitles, setHomePageTitles] = useState([]);
    const [bannerItems, setBannerItems] = useState([]);
    const [blogItems, setBlogItems] = useState([]);


    const getHomePageTitles = () => {
        console.log(language);
        axios.get(`https://cmsv2.dact.studio/api/homepage_settings/${language}`).then(
            (response) => {
                setHomePageTitles(response.data);
            }
        );
    };

    const title2 = homePageTitles[1]?.title; // sari siyah renk icin
    const title3 = homePageTitles[2]?.title;

    const getBannerItems = () => {
        axios.get(`https://cmsv2.dact.studio/api/banners/${language}`).then(
            (response) => {
                setBannerItems(response.data);
            }
        );
    };
    const getBlogItems = () => {
        axios.get(`https://cmsv2.dact.studio/api/blogs/${language}`).then(
            (response) => {
                setBlogItems([...response.data].reverse().slice(0, 3));
            }
        );
    };

    const renderTitle = (title) => {
        const words = title.split(" ");
        if (words.length > 1) {
            return (
                <>
                    <span className="color-yellow">{words[0]}</span>{" "}
                    {words.slice(1).join(" ")}
                </>
            );
        } else {
            return <span className="color-yellow">{words[0]}</span>;
        }
    };


    useEffect(() => {
        getHomePageTitles();
        getBannerItems();
        getBlogItems();
    }, []);


    return (
        <section className="index-page">
            <section className="hero">
                {/* <div className="container-fluid">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-center p-2">
                            <StaticImage src="../../images/pages/index/W.png" alt="kbo-logo" />
                        </div>
                    </div>
                </div> */}
                <div className="container-fluid slider-padding">
                    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">
                        <div class="carousel-indicators ">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div class="carousel-inner ">
                            {/* <div class="carousel-item active ">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <StaticImage src="../../images/app-image/banner1.png" className="" alt="kbo-product" class="d-block index-slider mx-auto" />
                                    </div>
                                    <div className="col-6 col-md-3 d-flex align-items-center">
                                        <div class="">
                                            <h5 className="d-none d-md-block text-dark">Kasa Takip Sistemi ile tanışın</h5>
                                            <p className="d-none d-md-block text-dark mt-4">Kasa Takip Sistemi sayesinde işlerinizi daha kolay ve daha hızlı halledin.</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {bannerItems.map((bannerItem, index) => {
                                return (
                                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <div className="row">
                                            <div className="col-12 col-md-7">
                                                <img src={bannerItem.image} class="d-block index-slider img-fluid mx-auto" alt="KBO" />
                                            </div>
                                            <div className="col-6 col-md-3 d-flex align-items-center">
                                                <div class="  ">
                                                    <h5 className="d-none d-md-block text-dark">{bannerItem.title}</h5>
                                                    <p className="d-none d-md-block text-dark mt-4">{bannerItem.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {/* <div class="carousel-item active">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <img src="https://dact-cms.s3.us-east-2.amazonaws.com/images/16700945633-1.jpg" class="d-block index-slider mx-auto" alt="..." />
                                    </div>
                                    <div className="col-6 col-md-3 d-flex align-items-center">
                                        <div class="  ">
                                            <h5 className="d-none d-md-block text-dark">Urun Numara 1</h5>
                                            <p className="d-none d-md-block text-dark mt-4">Urun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay Aciklama</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div class="carousel-item">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <img src="https://dact-cms.s3.us-east-2.amazonaws.com/images/16700940518-1.jpg" class="d-block index-slider mx-auto" alt="..." />
                                    </div>
                                    <div className="col-6 col-md-3 d-flex align-items-center">
                                        <div class="  ">
                                            <h5 className="d-none d-md-block text-dark">Urun Numara 1</h5>
                                            <p className="d-none d-md-block text-dark mt-4">Urun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay AciklamaUrun Detay Aciklama</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>
            <section style={{ backgroundColor: "rgba(241, 241, 241, 0.3)" }} className="p-3 mt-5 mb-5">
                <div className="container-fluid">
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-12">
                            <p className="questrial-60">{homePageTitles?.[0]?.title ?? ''}</p>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="d-flex align-items-center justify-content-center p-2">
                                <a href="#products"><StaticImage src="../../images/pages/index/down.png" alt="kbo-logo" /></a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            {/* <p className="questrial-25">Lorem ipsum dolor sit amet, <br /> consectetur adipiscing elit. </p> */}
                        </div>
                    </div>
                </div>
            </section>

            <CtaType1 color={{ backgroundColor: "#F1F1F1" }} text="allProducts" url="/products" />

            <div className="container py-5">
                <section className="app-section">
                    <div className="row">
                        <div className="col">
                            <div className="container-fluid slider-padding">
                                <div id="appCarousel" class="carousel slide" data-bs-ride="true">
                                    <div class="carousel-indicators ">
                                        <button type="button" data-bs-target="#appCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#appCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#appCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                        <button type="button" data-bs-target="#appCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                        <button type="button" data-bs-target="#appCarousel" data-bs-slide-to="4" aria-label="Slide 5"></button>
                                        <button type="button" data-bs-target="#appCarousel" data-bs-slide-to="5" aria-label="Slide 6"></button>
                                        <button type="button" data-bs-target="#appCarousel" data-bs-slide-to="6" aria-label="Slide 7"></button>
                                    </div>
                                    <div class="carousel-inner ">
                                        <div class="carousel-item active ">
                                            <div className="row">
                                                <div className="col-12 px-5 d-flex justify-content-center ">
                                                    <StaticImage src="../../images/app-image/1.png" className="" alt="kbo-product" height={600} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div className="row">
                                                <div className="col-12 px-5 d-flex justify-content-center">
                                                    <StaticImage src="../../images/app-image/2.png" className="" alt="kbo-product" height={600} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div className="row">
                                                <div className="col-12 px-5 d-flex justify-content-center">
                                                    <StaticImage src="../../images/app-image/3.png" className="" alt="kbo-product" height={600} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div className="row">
                                                <div className="col-12 px-5 d-flex justify-content-center">
                                                    <StaticImage src="../../images/app-image/4.png" className="" alt="kbo-product" height={600} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div className="row">
                                                <div className="col-12 px-5 d-flex justify-content-center">
                                                    <StaticImage src="../../images/app-image/5.png" className="" alt="kbo-product" height={600} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div className="row">
                                                <div className="col-12 px-5 d-flex justify-content-center">
                                                    <StaticImage src="../../images/app-image/6.png" className="" alt="kbo-product" height={600} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div className="row">
                                                <div className="col-12 px-5 d-flex justify-content-center">
                                                    <StaticImage src="../../images/app-image/7.png" className="" alt="kbo-product" height={600} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#appCarousel" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#appCarousel" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col d-flex align-items-center  justify-content-center">
                            <div style={{ textAlign: "center" }}>
                                <h1>Kasa Takip Sistemi ile tanışın</h1>
                                <h5 className="mt-3">Kasa Takip Sistemi sayesinde işlerinizi daha kolay ve daha hızlı halledin.</h5>
                                <a href="https://www.uncomsys.com/" target="_blank" className="cta btn btn-lg btn-outline-secondary mt-4 pt-3"><b>GİRİŞ YAP</b></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section className="services">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 d-flex justify-content-start align-items-end bg-w-gray">
                            {/* <p className="questrial-60 text-white"><span className="color-yellow"><Trans>Awesome</Trans> </span><Trans>Product</Trans></p> */}
                            <p className="questrial-60 text-white">
                                {title2 ? renderTitle(title2) : <Trans>Awesome Product</Trans>}
                            </p>
                        </div>
                        <div className="col-6 px-0">
                            <StaticImage src="../../images/pages/index/services1.png" alt="kbo-product" width={1000} height={500} objectFit="co" />
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-6 px-0">
                            <StaticImage src="../../images/pages/index/services2.png" alt="kbo-product" width={1000} height={500} objectFit="cover" />
                        </div>
                        <div className="col-6 d-flex justify-content-start align-items-end bg-w-gray">
                            {/* <p className="questrial-60 text-white"><span className="color-yellow"><Trans>Professional</Trans> </span><Trans>Service</Trans></p> */}
                            <p className="questrial-60 text-white">
                                {title3 ? renderTitle(title3) : <Trans>Awesome Product</Trans>}
                            </p>
                        </div>
                    </div>
                    {/* <NavigateLine color={{ backgroundColor: "#F8B500", color: "white" }} text="contact" url="/contact" /> */}

                </div>
            </section>

            <section>
                <div className="container p-5">
                    <div className="row">
                        {blogItems.map((blogItem, index) => {
                            return (
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div class="card">
                                        <div>
                                            <img src={blogItem.image} className="card-img-top" alt="kbo-product" height={300} />
                                        </div>
                                        <div class="card-body">
                                            <h5 class="card-title p-0 mt-2" style={{ textAlign: "left" }}>{blogItem.title}</h5>
                                            <p class="card-text">{blogItem.description} </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <CtaType2 color={{ backgroundColor: "#F8B500" }} text="contact" url="/contact" />

        </section>
    )
}
export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
