import React, { useState, useEffect } from "react"
import "./cta-type-1.scss"
import "../../typohraphy.scss"
import { FaArrowRight } from "react-icons/fa"
import { Trans } from 'gatsby-plugin-react-i18next';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import axios from "axios";
import { useI18next } from 'gatsby-plugin-react-i18next';

function CtaType1(props) {
    const { language, languages, changeLanguage } = useI18next();
    const [products, setProducts] = useState([]);


    const getProductCars = () => {
        axios.get(`https://cmsv2.dact.studio/api/product_cards`).then(
            (response) => {
                const updatedProducts = response.data.map(item => {
                    const translationMatch = item.product.product_translation.find(translation => translation.locale === language);
                    
                    if (translationMatch) {
                        return {
                            ...item,
                            name: translationMatch.name,
                            description: translationMatch.description,
                            // Diğer gerekli alanları da buraya ekleyebilirsiniz
                        };
                    }
    
                    return item; // Eğer uygun bir çeviri bulunamazsa orijinal ürün bilgisini döndür
                });
    
                setProducts(updatedProducts);
                console.log(updatedProducts);
            }
        );
    };
    

    useEffect(() => {
        getProductCars();
    }, [])
    

    // const products = [
    //     {
    //         id: 1,
    //         name: "KASALAR",
    //         image: "https://dact-cms.s3.us-east-2.amazonaws.com/images/16700940518-1.jpg",
    //         url: "/product-detail/paslanmaz-para-kanali"
    //     },
    //     {
    //         id: 2,
    //         name: "PANEL TİP ZAMAN RÖLESİ",
    //         image: "https://dact-cms.s3.us-east-2.amazonaws.com/images/16700945633-1.jpg",
    //         url: "/product-detail/panel-tip-zaman-rolesi"
    //     },
    //     {
    //         id: 3,
    //         name: "PASLANMAZ PARA KANALI",
    //         image: "https://dact-cms.s3.us-east-2.amazonaws.com/images/16700940518-1.jpg",
    //         url: "/product-detail/paslanmaz-para-kanali"
    //     },
    //     {
    //         id: 4,
    //         name: "LED GÖSTERGE PANELİ",
    //         image: "https://dact-cms.s3.us-east-2.amazonaws.com/images/167015369812-1.jpg",
    //         url: "/product-detail/led-gosterge-paneli/"
    //     },
    // ];

    return (
        // <Link to={props.url}>
        <>
            <div class=" mt-0 cta-page container-fluid">
                <div class="row" id="products">
                    <div className="col-lg-8 mt-2">
                    <Link to={'/product-detail/'+products[0]?.product.slug}>

                        <div className="text-center">
                            <img
                                src={products[0]?.product.cover}
                                alt={products[0]?.name}
                                style={{ width: '100%', objectFit: 'contain' }}
                            />

                        </div>
                            <div className="d-flex align-items-center justify-content-between ps-5">
                                <p className="questrial-50">{products[0]?.name}</p>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 product-list-box">
                        {products?.map((product) => (
                            <Link to={'/product-detail/'+product.product.slug} key={product.id} >
                                <div className="border p-2 container slider-item-bg">
                                    <div className="d-lg-flex d-md-block align-items-center list-box">
                                        <img src={product.product.cover} alt={product.name} width="210px" objectFit="contain" />
                                        <p className="ms-2">{product.name}</p>
                                    </div>
                                </div>
                            </Link>

                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-gray d-flex justify-content-between justify-align-center text-center p-5 align-items-center">
                <div>
                    {/* 003 <span className="text-secondary"> / 004</span> */}
                </div>
                <div>
                    <button className="cta btn btn-lg btn-outline-secondary "><b><Trans>see all</Trans></b></button>
                </div>
                <div>
                    {/* <i class="fa-solid fa-arrow-left-long"></i> &nbsp;
                        <i class="fa-solid fa-arrow-right-long"></i> */}
                </div>
            </div>
        </>


        // </Link>
    )
}

export default CtaType1;